import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'auth.accountant-register.success',
        path: '/accountant/register/success/:token',
        meta: { guard: GUARD.GUEST, layout: 'register-layout' },
        component: () => import('./pages/register-success.vue'),
    },
]

export default {
    routes,
    meta: { guard: GUARD.GUEST, layout: 'register-layout' },
}
