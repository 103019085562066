import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'accountant.projects',
        path: '/accountant/projects',
        redirect: {
            name: 'accountant.clients.projects',
        },
        children: [
            {
                name: 'accountant.clients.projects',
                path: 'list',
                component: () => import('./pages/accountant-projects.vue'),
            },
            {
                name: 'accountant.clients.projects.detail',
                path: ':id',
                component: () =>
                    import('./pages/accountant-projects-detail.vue'),
                redirect: {
                    name: 'accountant.clients.projects.detail.view',
                },
                children: [
                    {
                        name: 'accountant.clients.projects.detail.view',
                        path: 'view',
                        component: () =>
                            import(
                                './views/accountant-projects-detail-list.vue'
                            ),
                    },
                    {
                        name: 'accountant.clients.projects.detail.client-tasks',
                        path: 'client-tasks',
                        component: () =>
                            import(
                                './views/accountant-projects-detail-client-tasks.vue'
                            ),
                    },
                    {
                        name: 'accountant.clients.projects.detail.team-chat',
                        path: 'team-chat',
                        component: () =>
                            import(
                                './views/accountant-projects-detail-team-chat.vue'
                            ),
                    },
                    {
                        name: 'accountant.clients.projects.detail.files',
                        path: 'files',
                        component: () =>
                            import(
                                './views/accountant-projects-detail-files.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

export default {
    routes,
    meta: {
        noPadding: true,
        layout: 'accountant-layout',
        guard: GUARD.ACCOUNTANT,
    },
}
