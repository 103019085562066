import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'accountant',
        path: '/accountant',
        redirect: {
            name: 'accountant.clients',
        },
    },
]

export default {
    routes,
    meta: { layout: 'accountant-layout', guard: GUARD.ACCOUNTANT },
}
