import { GUARD } from '@tenant/utils/constants'
import { isActive } from '@tenant/core/feature'

const routes = [
    {
        name: 'home',
        path: '/',
        redirect: {
            name: 'home.index',
        },
        children: [
            {
                name: 'home.index',
                path: 'dashboard',
                component: () => import('./pages/new-home-page.vue'),
            },
            ...(isActive('FEATURE_FLAG_1082')
                ? [
                      {
                          name: 'home.getting-started',
                          path: 'dashboard/getting-started',
                          component: () =>
                              import('./pages/home-getting-started.vue'),
                      },
                  ]
                : []),
            {
                name: 'home.recent-updates',
                path: 'dashboard/recent-updates',
                component: () => import('./pages/home-recent-updates.vue'),
            },
            ...(isActive('FEATURE_FLAG_1082')
                ? [
                      {
                          name: 'home.announcements',
                          path: 'dashboard/announcements',
                          component: () =>
                              import('./pages/home-announcements.vue'),
                      },
                  ]
                : []),
        ],
    },
]

export default {
    routes,
    meta: { layout: 'dashboard-layout', guard: GUARD.TENANT },
}
