import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'accountant.team',
        path: '/accountant/team',
        redirect: { name: 'accountant.team.list' },
        children: [
            {
                name: 'accountant.team.list',
                path: 'list',
                component: () => import('./pages/accountant-team.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'accountant-layout',
        guard: GUARD.ACCOUNTANT,
        noPadding: true,
    },
}
