const routes = [
    {
        name: 'accountant.invite',
        path: '/accountant/invite/:token',
        component: () => import('./pages/invite-client.vue'),
    },
]

export default {
    routes,
    meta: { layout: 'auth-layout' },
}
