import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'accountant.setting',
        path: '/accountant/setting',
        redirect: {
            name: 'accountant.setting.company',
        },
        component: () => import('./pages/accountant-setting.vue'),
        children: [
            {
                name: 'accountant.setting.company',
                path: 'company',
                component: () =>
                    import('./views/accountant-setting-company.vue'),
            },
            {
                name: 'accountant.subscription-billing',
                path: 'subscription-billing',
                component: () => import('./views/accountant-subscription.vue'),
                redirect: {
                    name: 'accountant.subscription-billing.accountant',
                },
                children: [
                    {
                        name: 'accountant.subscription-billing.accountant',
                        path: 'accountant',
                        component: () =>
                            import(
                                './views/accountant-subscription-billing-accountant.vue'
                            ),
                    },
                    {
                        name: 'accountant.subscription-billing.clients',
                        path: 'clients',
                        component: () =>
                            import(
                                './views/accountant-subscription-billing-clients.vue'
                            ),
                    },
                ],
            },
            {
                name: 'accountant.setting.emails',
                path: 'emails',
                component: () =>
                    import('./views/accountant-setting-emails.vue'),
            },
        ],
    },
    {
        name: 'accountant.mail-connection.integrate',
        path: '/mail-connection/integrate',
        component: () =>
            import('./pages/accountant-setting-mail-integrate.vue'),
    },
]

export default {
    routes,
    meta: {
        layout: 'accountant-layout',
        guard: GUARD.ACCOUNTANT,
        noPadding: true,
    },
}
