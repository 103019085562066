import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'auth.register',
        path: '/register',
        meta: { guard: GUARD.GUEST, layout: 'register-layout' },
        component: () => import('./pages/register-user.vue'),
    },
    {
        name: 'auth.accountant-register',
        path: '/accountant/register',
        meta: { guard: GUARD.GUEST, layout: 'register-layout' },
        component: () => import('./pages/accountant-register.vue'),
    },
    {
        name: 'auth.verify-email',
        path: '/register/verify-email',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/verify-email.vue'),
    },
    {
        name: 'auth.verify-code',
        path: '/register/verify-code',
        meta: { guard: GUARD.CENTRAL },
        component: () => import('./pages/verify-code.vue'),
    },
    {
        name: 'auth.create-tenant',
        path: '/register/create-tenant',
        meta: { guard: GUARD.CENTRAL },
        component: () => import('./pages/create-company.vue'),
    },
    {
        name: 'auth.login-company',
        path: '/login/company',
        meta: { guard: GUARD.CENTRAL },
        component: () => import('./pages/login-company.vue'),
    },
    {
        name: 'auth.login',
        path: '/login',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/login-user.vue'),
    },
    {
        name: 'auth.verify-otp',
        path: '/login/verify',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/login-verify-otp.vue'),
    },
    {
        name: 'auth.login-social-callback',
        path: '/login/social-callback',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/login-social-callback.vue'),
    },
    {
        name: 'auth.social-integrate',
        path: '/social/integrate',
        meta: { guard: GUARD.TENANT },
        component: () => import('./pages/social-integrate.vue'),
    },
    {
        name: 'auth.forgot-password',
        path: '/forgot-password',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/forgot-password.vue'),
    },
    {
        name: 'auth.forgot-password.check-email',
        path: '/forgot-password/check-email',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/forgot-password-check-email.vue'),
    },
    {
        name: 'auth.forgot-password.set-new-password',
        path: '/forgot-password/set-new-password',
        meta: { guard: GUARD.GUEST },
        component: () => import('./pages/forgot-password-set-new.vue'),
    },
    {
        name: 'auth.invite-failed',
        path: '/invite/failed',
        component: () => import('./pages/invite-failed.vue'),
    },
    {
        name: 'auth.invite-accountant.welcome',
        path: '/invite/accountant/welcome',
        meta: { guard: GUARD.GUEST, layout: 'register-layout' },
        component: () => import('./pages/invite-accountant-welcome.vue'),
    },
    {
        name: 'auth.invite-success',
        path: '/invite/success/:token',
        component: () => import('./pages/invite-success.vue'),
        children: [
            {
                name: 'auth.invite-logged',
                path: '/invite/logged/:token',
                meta: { guard: GUARD.TENANT },
                component: () => import('./pages/invite-success-logged.vue'),
            },
            {
                name: 'auth.invite-login',
                path: '/invite/login/:token',
                meta: { guard: GUARD.GUEST },
                component: () => import('./pages/invite-success-login.vue'),
            },
            {
                name: 'auth.invite-register',
                path: '/invite/register/:token',
                meta: { guard: GUARD.GUEST },
                component: () => import('./pages/invite-success-register.vue'),
            },
            {
                name: 'auth.invite-accountant-register',
                path: '/invite/accountant/register/:token',
                meta: { guard: GUARD.GUEST, layout: 'register-layout' },
                component: () => import('./pages/accountant-register.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: { layout: 'auth-layout', permission: 'access' },
}
