import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'accountant.inbox',
        path: '/accountant/inbox',
        redirect: {
            name: 'accountant.inbox.chat',
        },
        component: () => import('./pages/accountant-inbox.vue'),
        children: [
            {
                name: 'accountant.inbox.chat',
                path: '',
                component: () =>
                    import(
                        '../../../components/shared/accountant-inbox-view.vue'
                    ),
            },
            {
                name: 'accountant.inbox.new-conversation',
                path: 'new-conversation',
                component: () =>
                    import(
                        '../../../components/shared/accountant-inbox-view-new-chat.vue'
                    ),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'accountant-layout',
        guard: GUARD.ACCOUNTANT,
        noPadding: true,
    },
}
