import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'accountant.clients',
        path: '/accountant/clients',
        redirect: {
            name: 'accountant.clients.clients',
        },
        children: [
            {
                name: 'accountant.clients.clients',
                path: 'overview',
                component: () => import('./pages/accountant-clients.vue'),
                meta: {
                    noPadding: true,
                },
            },
            {
                name: 'accountant.clients.add',
                path: 'overview/add',
                component: () => import('./pages/accountant-add-client.vue'),
            },
            {
                name: 'accountant.clients.detail',
                path: ':id',
                component: () =>
                    import('./pages/accountant-clients-detail.vue'),
                meta: {
                    noPadding: true,
                },
                redirect: {
                    name: 'accountant.clients.detail.dashboard',
                },
                children: [
                    {
                        name: 'accountant.clients.detail.dashboard',
                        path: 'list',
                        component: () =>
                            import('./views/accountant-clients-dashboard.vue'),
                    },
                    {
                        name: 'accountant.clients.detail.emails',
                        path: 'emails',
                        component: () =>
                            import('./views/accountant-clients-emails.vue'),
                    },
                    {
                        name: 'accountant.clients.detail.activity',
                        path: 'activity',
                        component: () =>
                            import('./views/accountant-clients-dashboard.vue'),
                    },
                    {
                        name: 'accountant.clients.detail.notes',
                        path: 'notes',
                        component: () =>
                            import('./pages/accountant-clients-notes.vue'),
                    },
                    {
                        name: 'accountant.clients.detail.documents',
                        path: 'documents',
                        component: () =>
                            import('./views/accountant-clients-documents.vue'),
                    },
                    {
                        name: 'accountant.clients.detail.client-vault',
                        path: 'client-vault',
                        component: () =>
                            import(
                                './pages/accountant-clients-client-vault.vue'
                            ),
                    },
                    {
                        name: 'accountant.clients.detail.transactions',
                        path: 'transactions',
                        component: () =>
                            import('./views/accountant-clients-dashboard.vue'),
                    },
                    {
                        name: 'accountant.clients.detail.task-report',
                        path: 'task-report',
                        component: () =>
                            import(
                                './views/accountant-clients-tasks-report.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'accountant-layout',
        guard: GUARD.ACCOUNTANT,
    },
}
